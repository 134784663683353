// BuildTimeInfo.js
import * as React from 'react';

const BuildTimeInfo = ({ buildTime }) => {
  return (
    <div>
      <p>Built at: {buildTime}</p>
    </div>
  );
};

export default BuildTimeInfo;
